import React from 'react';
import VideoListItem from './videolistitem';
import YouTube from 'react-youtube';
class VideoList extends React.Component{
    constructor(props){
        super(props);
        this.state={
            viddiv:''
        }
    }
   
    opts = {
        height: '390',
        width: '100%',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 1,
        },
      };
    videoClick = (videoinfo)=>{
        console.log("Video info: ",videoinfo)
        this.setState({
            viddiv: <YouTube videoId={videoinfo.id.videoId} opts={this.opts} onReady={this._onReady} />
        })
        
    }
    render(){
    let vidlist = this.props.videos.map((video)=><VideoListItem video={video} key={video.etag} 
    videoClick={()=>this.videoClick(video)}/>)
    
    return(
        <div className='row' style={{width:'100%',marginTop:'40px'}}>
        {this.state.viddiv&&<div className='col-md-8'>
            {this.state.viddiv}
        </div>}
        <ul className='col-md-4 list-group'>
            {vidlist}
        </ul>
      
        </div>
    );
    }
    _onReady(event) {
        // access to player in all event handlers via event.target
        // event.target.pauseVideo();
      }
};

export default VideoList;