import axios from "axios";
const YTKEY = "AIzaSyDZPdgOgtDBUdVbLtMQGUGbHF6JrNNUalU"

export const listVideos=()=>{
   return axios.create({
        baseURL:'https://www.googleapis.com/youtube/v3/',
        params:{
            part: 'snippet',
            maxResults: 10,
            key: YTKEY
        }
    })
}

export const getVideoStats = (id)=>{
    return axios.create({
        baseURL:'https://www.googleapis.com/youtube/v3/',
        params:{
            part:'statistics',
            id:id,
            key:YTKEY
        }
       
    })
}



