import React,{Component} from 'react';
import {Form} from 'react-bootstrap';
import { FaSearch } from "react-icons/fa";

// const SearchBar = () =>{
//     return <input />
// }
class SearchBar extends Component{
    constructor(props){
        super(props);

        this.state = {
            searchTerm: ''
        }
    }
    render(){
 
        return(
            <div>
            <form onSubmit={this.handleSearchClick}>
           <div className='row' style={searchRow}>
           <Form.Group className="col-4" controlId="exampleForm.ControlInput1">
                <Form.Control type="text" onChange={(event)=>this.setState({searchTerm:event.target.value})} 
                on
            placeholder={this.props.value}
            value={this.state.searchTerm}/>
            </Form.Group>
            <div className='col-1' style={divStyle}>
            <button type='submit' style={{border:'none',padding:0,backgroundColor:'transparent'}}><FaSearch/></button>
            </div>
           </div>
           </form>
           
         
            
            </div>
        );
    }
    handleSearchClick=(event)=>{
        this.props.searchDetails(this.state.searchTerm)
        event.preventDefault(); //Used to prevent browser reload/refresh otherwise for each typed letter the function would be called
    }


 
}

const divStyle = {
    justifyContent: "flex-start",
    alignItems: "center",
    display: "flex",
    marginLeft: 0,
    paddingLeft: 0
  };
const searchRow={
  
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
    
}
export default SearchBar;