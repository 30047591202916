import React from 'react';
class VideoListItem extends React.Component{
     render(){
    return(<div style={{marginBottom:'5px',marginTop:'10px',display:'flex',flexDirection:'row'}} onClick={this.props.videoClick}>
       
            <img src={this.props.video.snippet.thumbnails.default.url} className='w-25'></img>
       
       <div style={{display:'flex',flexDirection:'column',width:'75%',padding:0}}>
            <div> <span>{this.props.video.snippet.title}</span></div>
            <div><span className='text-muted'>{this.props.video.snippet.channelTitle}</span></div>
            <div><span>{this.getViews()}</span></div>
       </div>
    </div>)
}
getViews(){
     // if(this.props.video.stats.items[0])
}
}

export default VideoListItem;