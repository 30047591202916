import React from 'react';
import  ReactDOM  from 'react-dom';
import SearchBar from './components/searchbar';
import YTSearch from 'youtube-api-search';
import VideoList from './components/videolist';
import 'bootstrap/dist/css/bootstrap.min.css';
// import {listVideos,get from './services/youtube.service';
import { listVideos,getVideoStats } from './services/youtube.service';


const YT_API_KEY = "AIzaSyDZPdgOgtDBUdVbLtMQGUGbHF6JrNNUalU"


class App extends React.Component{
    constructor(props){
        super(props);
        
        
        this.state={
            videoList:[]
        }
       
    }
    handleSearch=async (searchData) =>{
        console.log("Search data recvd: ",searchData)
        const ytresponse = await listVideos().get('/search',{
            params:{
                q:searchData
            }
        })
        console.log("Axios Yt response: ",ytresponse)
        this.setState({videoList:ytresponse.data.items})
        let items = this.state.videoList
        items.forEach(async(ele,index,arr)=>{
            const stats = await getVideoStats(ele.id.videoId).get('/videos')
            arr[index]["stats"] =stats.data
        })
      
        // console.log("Video stats: ",stats)
        console.log("Video data: ",this.state.videoList)
        // YTSearch({key:YT_API_KEY, term:searchData},(data)=>{
        //     this.setState({videoList:data})
        //     console.log("Video data: ",this.state.videoList)
        // })
    }
    render(){
    return (<div className='container-fluid' style={{margin:'25px'}}>
        <SearchBar value={'Enter video you want to watch: '} searchDetails={this.handleSearch}/>
        <VideoList videos={this.state.videoList}/>
    </div>
    );
}

}

ReactDOM.render(<App />,
    document.getElementById('root')//Providing an entry level node in the DOM to render our components to
    );